import React, { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"
import { Section } from "../../components/Containers"
import { Image, Text } from "../../components/Core"
import Dialog from "../../components/Modals/Dialog"

const TeamMember = props => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDialog = () => {
    setIsOpen(false)
  }
  return (
    <>
      <div
        className="column is-4 mt-3--mobile px-40--mobile staff-row__card"
        key={props.publicId}>
        <div className="staff-row__image-wrapper">
          <Image cloudName="nuvolum" publicId={props.publicId} />
          {props.quote && (
            <button
              onClick={() => setIsOpen(true)}
              className="reset staff-row__button">
              <p>
                Learn More <FontAwesomeIcon icon={faArrowRight} />
              </p>
            </button>
          )}
        </div>
        <div className="has-text-centered mt-1 staff-row__card-titles">
          <h4 className="mb-0 mt-0">{props.staffName}</h4>
          <p className="mb-0 mt-0">{props.staffTitle}</p>
        </div>
      </div>
      <Dialog
        className="staff-row__dialog"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}>
        <div className="staff-row__dialog--grid">
          <div>
            <Image publicId={props.publicId} />
            <h4 className="mb-0 mt-0 mt-1--mobile">{props.staffName}</h4>
          </div>
          <div className="staff-row__dialog--quote">
            <h4 style={{ fontSize: "20px" }} className="mb-1 mt-0 bold--500">
              {props.staffTitle}
            </h4>
            <p>{props.quote}</p>
          </div>
        </div>
      </Dialog>
    </>
  )
}

TeamMember.propTypes = {
  publicId: PropTypes.string,
  staffName: PropTypes.string,
  staffTitle: PropTypes.string
}

function TeamRow(props) {
  const sideColumns = classNames({
    "is-2": props.members.length >= 4
  })
  return (
    <div className={`columns staff-row ${props.last ? "last" : ""}`}>
      <div className={`column ${sideColumns}`}></div>
      {props.members.map(member => {
        return (
          <TeamMember
            key={member.staffMember.imageId}
            publicId={member.staffMember.imageId}
            staffName={member.staffMember.staffName}
            quote={member.staffMember.quote}
            staffTitle={
              props.language === "es"
                ? member.staffMember.spanishTitle
                : member.staffMember.staffTitle
            }
          />
        )
      })}
      <div className={`column ${sideColumns}`}></div>
    </div>
  )
}

TeamRow.propTypes = {
  members: PropTypes.array
}

function MeetOurTeam(props) {
  if (!props.post.meetOurTeam.hasThisSection) return <></>

  var members = props.team

  var memberRows = []
  var size = 5

  for (var i = 0; i < members.length; i += size) {
    memberRows.push(members.slice(i, i + size))
  }

  return (
    <Section zeroTop colorBack={props.colorBack} className="meet-our-team">
      <div className="columns meet-our-team-heading">
        <div className="column is-4"></div>
        <div className="column mobile-col has-text-centered-tablet px-40--mobile">
          <Text
            className="meet-our-team__content"
            text={props.post.meetOurTeam.text}
          />{" "}
        </div>
        <div className="column is-4"></div>
      </div>

      {props.post.meetOurTeam.showStaff && (
        <>
          {memberRows.map((row, i) => {
            return (
              <React.Fragment key={i}>
                <TeamRow
                  language={props.language}
                  listKey={i}
                  members={row}
                  last={i == memberRows.length - 1 ? true : false}
                />
              </React.Fragment>
            )
          })}
        </>
      )}
    </Section>
  )
}

MeetOurTeam.propTypes = {
  invert: PropTypes.bool,
  post: PropTypes.shape({
    meetOurTeam: PropTypes.shape({
      text: PropTypes.string,
      staffImages: PropTypes.array
    }).isRequired
  }).isRequired
}

export default MeetOurTeam
