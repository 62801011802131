import React from "react"
import { Columns, Section } from "../../components/Containers"
import { Text } from "../../components/Core"

function OurMission(props) {
  return (
    <Section className="our-mission" smallSpacing>
      <Columns sideColumnsSize={2}>
        <div className="column px-40--mobile">
          <Text text={props.post.ourMission} />
        </div>
        <div className={`column is-2`}></div>
      </Columns>
    </Section>
  )
}

export default OurMission
